exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-components-contact-form-tsx": () => import("./../../../src/pages/components/contact-form.tsx" /* webpackChunkName: "component---src-pages-components-contact-form-tsx" */),
  "component---src-pages-components-footer-tsx": () => import("./../../../src/pages/components/footer.tsx" /* webpackChunkName: "component---src-pages-components-footer-tsx" */),
  "component---src-pages-components-header-tsx": () => import("./../../../src/pages/components/header.tsx" /* webpackChunkName: "component---src-pages-components-header-tsx" */),
  "component---src-pages-components-hero-tsx": () => import("./../../../src/pages/components/hero.tsx" /* webpackChunkName: "component---src-pages-components-hero-tsx" */),
  "component---src-pages-components-instagram-tsx": () => import("./../../../src/pages/components/instagram.tsx" /* webpackChunkName: "component---src-pages-components-instagram-tsx" */),
  "component---src-pages-components-layout-tsx": () => import("./../../../src/pages/components/layout.tsx" /* webpackChunkName: "component---src-pages-components-layout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-food-tsx": () => import("./../../../src/pages/food.tsx" /* webpackChunkName: "component---src-pages-food-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lifestyle-tsx": () => import("./../../../src/pages/lifestyle.tsx" /* webpackChunkName: "component---src-pages-lifestyle-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

